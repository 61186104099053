import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles/styles.css";
import "./styles/hero.css";
import "./styles/navbar.css";
import "./styles/Navbar-Centered-Brand-icons.css";
import "./styles/Testimonials-images.css";
import "./styles/Pricing-Duo-badges.css";
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap";

const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const LandingPage = () => {
  const [navBackground, setNavBackground] = useState(false);
  const [email, setEmail] = useState<string>("");

  const handleScroll = () => {
    setNavBackground(window.scrollY > 150);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubscribeClick = async () => {
    if (!email || !validateEmail(email)) {
      return alert("Please enter a valid email");
    }

    fetch(
      "https://us-central1-backpack-be952.cloudfunctions.net/subscribeUserToEmailList",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    )
      .then(() => {
        alert("All set!");
        setEmail("");
      })
      .catch((err) => {
        alert("There was an error. Please try again.");
      });
  };

  return (
    <Container fluid style={{ padding: 0 }}>
      <Navbar
        expand={"md"}
        className="navbar navbar-light navbar-expand-md fixed-top py-3 navbar-transparent"
        style={{
          transition: "0.3s ease",
          backgroundColor: navBackground ? "#fff" : "transparent",
        }}
      >
        <Container>
          <Nav.Link className="navbar-brand d-flex align-items-center" href="#">
            <span className="text-dark" style={{ fontWeight: 900 }}>
              <Image className="brand" src="/images/splash-logo.png" />
              Backpack
            </span>
          </Nav.Link>
          <Navbar.Toggle aria-controls={"responsive-navbar-nav"} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link active" href="#hero">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#features">
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#pricing">
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li>
            </ul>
            {/*<button*/}
            {/*  className="btn btn-signup"*/}
            {/*  type="button"*/}
            {/*  style={{*/}
            {/*    transition: "0.5s ease",*/}
            {/*    borderWidth: 0,*/}
            {/*    color: navBackground ? "black" : "white",*/}
            {/*    marginRight: 16,*/}
            {/*    fontWeight: "bold",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Login*/}
            {/*</button>*/}
            {/*<button*/}
            {/*  className="btn btn-signup"*/}
            {/*  type="button"*/}
            {/*  style={{*/}
            {/*    transition: "0.5s ease",*/}
            {/*    borderColor: navBackground ? "black" : "white",*/}
            {/*    color: navBackground ? "black" : "white",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Create Account*/}
            {/*</button>*/}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container
        fluid
        id={"hero"}
        className="hero"
        style={{
          background: `url('${process.env.PUBLIC_URL}/images/frame.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row">
          <div className="col-md-2">
            <div />
          </div>
          <div className="col-md-4">
            <h1
              className="pulse animated"
              style={{ fontSize: "52.36px", fontWeight: 900, marginTop: 58 }}
            >
              <span style={{ color: "rgb(51, 51, 51)" }}>
                A new way to organize&nbsp;
              </span>
              <br />
              <span style={{ color: "rgb(27, 113, 252)" }}>
                your school life
              </span>
            </h1>
            <p
              className="pulse animated"
              style={{
                fontSize: 20,
                marginTop: 32,
                color: "var(--bs-secondary)",
                marginBottom: 16,
              }}
            >
              <span style={{ color: "rgb(82, 97, 107)" }}>
                Your assignments. Your notifications. Your time. Your Devices.
              </span>
            </p>
            <div style={{ marginTop: 44 }}>
              <a
                href="https://apps.apple.com/us/app/homework-tracker-by-backpack/id1482207446"
                target="_blank"
              >
                <img
                  className="jello animated"
                  src="/images/app_store.svg"
                  style={{
                    marginRight: 19,
                    boxShadow: "0px 2px 3px 2px rgba(105,108,111,0.1)",
                  }}
                  alt="Download on app store"
                />
              </a>
            </div>
          </div>
          <div className="col-md-6 offset-lg-0">
            <img
              className="bounce animated"
              src="/images/1phone.png"
              style={{
                width: 447,
                paddingTop: 0,
                paddingRight: 32,
                paddingLeft: 32,
              }}
            />
          </div>
        </div>
      </Container>
      <div className="container" id={"features"} style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col-md-12">
            <h1
              className="text-center"
              style={{ fontSize: "52.36px", fontWeight: 900 }}
            >
              Your. New.{" "}
              <span style={{ color: "var(--bs-primary)", fontWeight: 900 }}>
                Superpower.
              </span>
            </h1>
          </div>
        </div>
        <div className="row" style={{ marginTop: 48 }}>
          <div className="col-md-1">
            <div />
          </div>
          <div className="col-md-5">
            <img
              className="img-fluid"
              src="/images/2phone.png"
              style={{ paddingRight: 100, paddingLeft: 100 }}
            />
          </div>
          <div className="col-md-5">
            <h1
              style={{
                marginTop: 64,
                color: "var(--bs-body-color)",
                fontSize: 42,
                fontWeight: 900,
              }}
            >
              <span style={{ color: "rgb(51, 51, 51)" }}>
                Unlimited Notifications
              </span>
            </h1>
            <ul className="list-unstyled" style={{ marginTop: 48 }}>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Notifications on each assignment</span>
              </li>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Set a specific time for each class</span>
              </li>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Create as many as you need (no judgement here)</span>
              </li>
            </ul>
          </div>
          <div className="col-md-1">
            <div />
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col-md-1">
            <div />
          </div>
          <div className="col-md-5">
            <h1
              style={{
                marginTop: 64,
                fontWeight: 900,
                color: "var(--bs-body-color)",
                fontSize: 42,
              }}
            >
              <span style={{ color: "rgb(51, 51, 51)" }}>
                Unlimited Classes
              </span>
            </h1>
            <ul className="list-unstyled" style={{ marginTop: 48 }}>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Customize your classes</span>
              </li>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Set office hours & schedules</span>
              </li>
              <li className="d-flex mb-2">
                <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-check-lg"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </span>
                <span>Attach instructors to your classes</span>
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <img
              className="img-fluid"
              src="/images/3phone.png"
              style={{ paddingRight: 100, paddingLeft: 100 }}
            />
          </div>
          <div className="col-md-1">
            <div />
          </div>
        </div>
      </div>
      <div
        className="container-fluid"
        style={{ background: "#f1f2f4", marginTop: 100, padding: "100px 32px" }}
      >
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2
              style={{
                fontSize: "52.36px",
                color: "var(--bs-gray-900)",
                fontWeight: 900,
              }}
            >
              What Our Users Say
            </h2>
          </div>
        </div>
        <div className="row gy-4 row-cols-1 row-cols-sm-2 row-cols-lg-3">
          <div className="col">
            <div>
              <p className="bg-light border rounded border-0 border-light p-4">
                Such a great app! it’s an app i never knew i needed till i
                downloaded it! you can set up multiple remind times to help you
                remind, organize classes and assignments with aesthetically
                pleasing colors! and it’s really easy to cross off that you got
                that assignment done!
              </p>
            </div>
          </div>
          <div className="col">
            <div>
              <p className="bg-light border rounded border-0 border-light p-4">
                I’ve been searching for an app to help me organize my school
                work for a few weeks now and this is the best one i’ve found!
                it’s a pretty app, with a nice and efficient layout and it
                functions perfectly. i was also wanting an app that had a good
                variety in color options for classes, and this app has beautiful
                cool tone colors to choose from!
              </p>
            </div>
          </div>
          <div className="col">
            <div>
              <p className="bg-light border rounded border-0 border-light p-4">
                I used to struggle with organization because I never found a
                planner to fit my liking. I got to build my own planner with
                this app. I recommend schools use it. I recommend everyone uses
                it. I love it so much. This makes me a more confident student.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        id={"pricing"}
        style={{ paddingBottom: 100, paddingTop: 100 }}
      >
        <div className="row mb-5">
          <div className="col-md-8 col-xl-6 text-center mx-auto">
            <h2 style={{ fontWeight: 900, fontSize: "52.36px" }}>Pricing</h2>
            <p className="w-lg-50">Choose the plan that works for you.</p>
          </div>
        </div>
        <div className="row gy-4 gx-md-0 gy-md-0 row-cols-1 row-cols-md-2 row-cols-xl-3 d-md-flex d-xl-flex align-items-md-center">
          <div className="col offset-xl-2">
            <div className="card bg-light border-0">
              <div className="card-body p-4" style={{ background: "#f3f5f7" }}>
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="fw-bold mb-0">Free Plan</h3>
                    <p
                      className="fw-lighter"
                      style={{
                        marginBottom: 0,
                        color: "var(--bs-secondary)",
                        fontSize: 18,
                      }}
                    >
                      Love the possibilities.
                    </p>
                    <p
                      className="fw-lighter"
                      style={{ color: "var(--bs-secondary)", fontSize: 18 }}
                    >
                      Love the (free) price.
                    </p>
                    <h4 className="display-6 fw-bold">FREE</h4>
                  </div>
                </div>
                <div>
                  <ul className="list-unstyled">
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Unlimited Classes &amp; Assignments</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Access to all calendar screens</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Set notifications per assignment</span>
                    </li>
                  </ul>
                </div>
                {/*<a*/}
                {/*  className="btn btn-primary d-block w-100"*/}
                {/*  role="button"*/}
                {/*  href="#"*/}
                {/*  style={{ background: "var(--bs-primary)" }}*/}
                {/*>*/}
                {/*  Sign Up*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <div className="col">
            <div
              className="card text-white bg-primary border-0"
              style={{ background: "var(--bs-primary)", borderRadius: 12 }}
            >
              <div className="card-body p-4">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="fw-bold text-white mb-0">Pro Plan</h3>
                    <p className="fw-lighter" style={{ fontSize: 18 }}>
                      Personal is powerful.
                    </p>
                    <h4 className="display-6 fw-bold text-white">
                      $2.99<span style={{ fontSize: 18 }}>/month</span>
                    </h4>
                  </div>
                  <div>
                    <span className="badge rounded-pill bg-primary text-uppercase bg-white-300">
                      GREAT Value
                    </span>
                  </div>
                </div>
                <div>
                  <ul className="list-unstyled">
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Unlimited notifications - everywhere</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Sync accounts across devices</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>No ads</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Advanced classes</span>
                    </li>
                    <li className="d-flex mb-2">
                      <span className="bs-icon-xs bs-icon-rounded bs-icon-semi-white bs-icon me-2 xs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          className="bi bi-check-lg"
                        >
                          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                        </svg>
                      </span>
                      <span>Access to class timelines &amp; schedules</span>
                    </li>
                  </ul>
                </div>
                {/*<a*/}
                {/*  className="btn btn-primary d-block w-100 bg-white-300"*/}
                {/*  role="button"*/}
                {/*  href="#"*/}
                {/*>*/}
                {/*  Get Started!*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <section>
          <div
            style={{
              height: 600,
              background: 'url("/images/4phones.png")',
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            className="container h-100 position-relative"
            style={{ top: "-50px" }}
          >
            <div className="row gy-5 gy-lg-0 row-cols-1 row-cols-md-2 row-cols-lg-3">
              <div className="col"></div>
              <div className="col">
                <div className="card border-0">
                  <div className="card-body pt-5 p-4">
                    <h4
                      className="card-title"
                      style={{ fontFamily: "Inter", fontWeight: 900 }}
                    >
                      What are you waiting for?
                    </h4>
                    <h6
                      className="text-muted card-subtitle mb-2"
                      style={{ fontFamily: "Inter" }}
                    >
                      Backpack is THE app for you.
                    </h6>
                    <p className="card-text" style={{ fontFamily: "Inter" }}>
                      Created and developed by a student, I know just how
                      difficult it can be to organize your school life. So
                      don't. Let Backpack do it for you.
                    </p>
                  </div>
                  <div className="card-footer border-0 p-4 py-3 d-flex justify-content-center">
                    <a
                      href="https://apps.apple.com/us/app/homework-tracker-by-backpack/id1482207446"
                      target="_blank"
                    >
                      <img
                        src="/images/app_store.svg"
                        style={{
                          marginRight: 19,
                          boxShadow: "0px 2px 3px 2px rgba(105,108,111,0.1)",
                        }}
                        alt="Download on app store"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="text-white bg-dark border rounded border-0 border-light d-flex flex-column justify-content-between align-items-center flex-lg-row p-4 p-lg-5 subscribe-card">
              <div className="text-center text-lg-start py-3 py-lg-1">
                <h2 className="fw-bold mb-2">
                  <strong>Subscribe to our newsletter</strong>
                </h2>
                <p className="mb-0">
                  Want to receive updates on what is next in Backpack? Subscribe
                  to our newsletter
                </p>
              </div>
              <div className="d-flex justify-content-center flex-wrap my-2">
                <div className="my-2">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="my-2">
                  <button
                    onClick={handleSubscribeClick}
                    className="btn btn-primary ms-sm-2"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
          <footer className="text-center bg-dark" style={{ marginTop: 64 }}>
            <div className="container text-white py-4 py-lg-5">
              <ul className="list-inline">
                <li className="list-inline-item me-4">
                  <a className="link-light" href="#">
                    Contact
                  </a>
                </li>
                <li className="list-inline-item me-4">
                  <a className="link-light" href="#">
                    About
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="link-light" href="#">
                    Download
                  </a>
                </li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-facebook text-light"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </li>
                <li className="list-inline-item me-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-twitter text-light"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </li>
                <li className="list-inline-item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    className="bi bi-instagram text-light"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </li>
              </ul>
              <p className="text-muted mb-0">Copyright © 2022 Backpack V1</p>
            </div>
          </footer>
        </section>
      </>
    </Container>
  );
};
